:root {
  --clr-bg: #121212;
  --clr-fg: #fff;
  --clr-00dp: hsl(0, 0%, 7%);
  --clr-01dp: hsl(0, 0%, 11%);
  --clr-02dp: hsl(0, 0%, 13%);
  --clr-03dp: hsl(0, 0%, 14%);
  --clr-04dp: hsl(0, 0%, 15%);
  --clr-06dp: hsl(0, 0%, 17%);
  --clr-08dp: hsl(0, 0%, 18%);
  --clr-12dp: hsl(0, 0%, 20%);
  --clr-16dp: hsl(0, 0%, 21%);
  --clr-24dp: hsl(0, 0%, 22%);
  --clr-bg-transparent: hsla(0, 0%, 12%, 90%);
  --clr-bg-transparent2: rgba(0, 0, 0, 0.5);
}

/* CSS Boilerplate https://www.joshwcomeau.com/css/custom-css-reset/*/

*, *::before, *::after {
  box-sizing: border-box;
}

* {
  margin: 0;
}

html, body {
  height: 100%;
  scroll-behavior: smooth;
  scroll-padding-top: 6rem;
}

body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
}

img, picture, video, canvas, svg {
  display: block;
  max-width: 100%;
}

input, button, textarea, select {
  font: inherit;
}

p, h1, h2, h3, h4, h5, h6 {
  overflow-wrap: break-word;
}

#root, #__next {
  isolation: isolate;
}

/* CSS */

/* General styling */
body {
  color: white;
  background-color: #121212;
  font-family: 'Noto Sans', sans-serif;
  font-size: 18px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  inset: 0;
  height: 100vh;
  user-select: none;
}
body:before {
  content: "";
  position: fixed;
  width: 100%;
  height: 100%;
  background-image: url("./components/registration/bg.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  filter: blur(0.3vh);
  transform: scale(1.1);
  z-index: -9;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 400px;
  background-color: var(--clr-bg-transparent2);
  padding: 3rem;
  border-radius: 20px;
  text-align: center;
  gap: 2rem;
}

.container img {
  max-width: 140px;
  border-radius: 100vw;
}

.login {
  background: var(--clr-02dp);
  color: white;
  text-decoration: none;
  border: none;
  border-radius: 10px;
  width: 100%;
  padding: 5px;
  cursor: pointer;
}

.login:hover {
  background: var(--clr-12dp);
}

.name {
  background-color: var(--clr-bg);
  color: white;
  border: none;
  padding: 5px 10px;
  width: 100%;
  border-radius: 10px;
}

input {
  margin: 0.5rem 0;
}

input:focus {
  outline: none;
}

#dc-name {
  font-size: 26px;
  margin-top: -2.2rem;
}

p {
  font-weight: normal;
}

#root {
  width: 100%;
  height: 100%;
}

#content {
  height: 100%;
}

#profile {
  background-color: var(--clr-bg-transparent2);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  padding: 1rem 2rem;
  position: fixed;
}

#profile div {
  display: flex;
  align-items: center;
  gap: 1rem;
  color: #666;
}

.btn {
  background: var(--clr-02dp);
  border: none;
  border-radius: 10px;
  padding: 5px 10px;
  color: var(--clr-fg);
  cursor: pointer;
  text-decoration: none;
}

.btn:hover {
  background: var(--clr-12dp);
}

#avt img {
  border-radius: 100vw;
  max-width: 50px;
}

#avt img:hover {
  outline: 1px solid #666;
}

#outlet {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
}

#outlet > div {
  background-color: var(--clr-bg-transparent2);
  padding: 2rem;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 2rem;
}

.tournament-container {
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 1rem;
}